import { FaceFormatListItemProps } from "../interfaces/faceFormatList";

export const FaceFormatList: FaceFormatListItemProps[] = [
  {
    name: 'Oval',
    imgPath: 'faces/1-face-oval.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/oval/',
    thumbImg: 'thumbs/1-oval-thumb.png',
  },
  {
    name: 'Redondo',
    imgPath: 'faces/2-face-redondo.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/redondo/',
    thumbImg: 'thumbs/2-redondo-thumb.png',
  },
  {
    name: 'Quadrado',
    imgPath: 'faces/3-face-quadrado.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/quadrado/',
    thumbImg: 'thumbs/3-quadrado-thumb.png',
  },
  {
    name: 'Retangular',
    imgPath: 'faces/4-face-retangular.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/redondo/',
    thumbImg: 'thumbs/4-retangular-thumb.png',
  },
  {
    name: 'Hexagonal Base Reta',
    imgPath: 'faces/5-face-hbasereta.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/hexagonal-de-base-reta/',
    thumbImg: 'thumbs/5-hbasereta-thumb.png',
  },
  {
    name: 'Hexagonal Lateral Reta',
    imgPath: 'faces/6-face-hlateralreta.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/hexagonal-de-lateral-reta/',
    thumbImg: 'thumbs/6-hlateralreta-thumb.png',
  },
  {
    name: 'Triângulo Invertido',
    imgPath: 'faces/7-face-triangulo-invertido.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/triangulo-invertido/',
    thumbImg: 'thumbs/7-trianguloinvertido-thumb.png',
  },
  {
    name: 'Triangular',
    imgPath: 'faces/8-face-triangular.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/triangular/',
    thumbImg: 'thumbs/8-triangular-thumb.png',
  },
  {
    name: 'Losango',
    imgPath: 'faces/9-face-losango.png',
    categoryLink: 'https://unicjewels.com.br/categoria-produto/tipos-de-rostos/losango/',
    thumbImg: 'thumbs/9-losango-thumb.png',
  },
];